export const menuItems = [
    // {
    //     id: 1,
    //     label: 'menuitems.dashboard.text',
    //     icon: 'uil-home-alt',
    //     link: '/teacher-registed/main',
    // },
    // {
    //     id: 1,
    //     label: 'menuitems.teacher_registration.text',
    //     icon: 'uil-pen',
    //     link: '/teacher-registed',
    // },
    {
        id: 2,
        label: 'menuitems.course_registed.text',
        icon: 'uil-book-open',
        link: '/course-registed',
        thname: "หน้าหลัก",
    },
]
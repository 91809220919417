<script>
export default {
    props: {
        isShow: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
    },
    components: {
    },
    data() {
        return {
           
        }
    },
}
</script>

<template>
    <b-modal size="md" v-model="isShow" :title="title" no-close-on-esc no-close-on-backdrop hide-header-close>
        <h4 class="text-success">{{ text }}</h4>
       
        <template #modal-footer>
            <div class="w-100 d-block d-grid">
                <b-button variant="danger" size="xl" @click="$emit('confirm');">
                    ยืนยัน
                </b-button>
            </div>

            <div class="w-100 d-block d-grid">
                <b-button variant="info" size="xl" @click="$emit('close');">
                    ย้อนกลับ
                </b-button>
            </div>
        </template>
    </b-modal>
</template>
<script>
export default {
    
}
</script>

<template>
    <footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                ติดต่อสอบถาม <a href="https://lin.ee/oT7LX3R" target="_blank">สภาการแพทย์แผนไทย</a><br/>
                {{ new Date().getFullYear()}} © มูลนิธิรวมพัฒน์ <img src=""/>
            </div>
        </div>
    </div>
</footer>
</template>